import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { useEffect } from 'react';
import {
  checkIfUserHasPremiumOnAnyAccount,
  openCheckoutPage,
  OpenCheckoutPageParams,
} from '../utilities/paywallHelper';
import { fetchOffering } from '../features/offering/offeringSlice';
import { useCheckoutConversionLogging } from '../app/useCheckoutConversionLogging';
import { useAppDispatch } from '../app/hooks';

function usPremiumStatus(): PaywallContextValues {
  const [hasPremium, setHasPremium] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useCheckoutConversionLogging();

  const fetchPremiumStatus = useCallback(async () => {
    const userHasPremium = await checkIfUserHasPremiumOnAnyAccount();
    setHasPremium(userHasPremium);
  }, []);

  const fetchIAPStatus = useCallback(async () => {
    fetchPremiumStatus();
  }, [fetchPremiumStatus]);

  const configureIAP = useCallback(async () => {
    dispatch(fetchOffering());
  }, []);

  const purchasePremium = useCallback(async (params: OpenCheckoutPageParams) => {
    openCheckoutPage(params);
  }, []);

  useEffect(() => {
    configureIAP()
      .then(() => {
        fetchIAPStatus();
      })
      .catch(console.log);
  }, []);

  return { hasPremium, purchasePremium };
}

export type PaywallContextValues = {
  hasPremium: boolean;
  purchasePremium: (checkoutParams: OpenCheckoutPageParams) => Promise<void>;
};

const PaywallContext = createContext<PaywallContextValues>(null as any);

type PaywallProviderProps = {
  children: ReactNode;
};

export const PaywallProvider: React.FC<PaywallProviderProps> = ({ children }) => {
  const { hasPremium, purchasePremium } = usPremiumStatus();

  const value: PaywallContextValues = {
    hasPremium,
    purchasePremium,
  };

  return <PaywallContext.Provider value={value}>{children}</PaywallContext.Provider>;
};

// Custom hook to use the Paywall context
export const usePaywall = () => {
  const context = useContext(PaywallContext);
  if (context === undefined) {
    throw new Error('usePaywall must be used within a PaywallProvider');
  }
  return context;
};
