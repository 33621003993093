export const NUMBER_OF_FREE_UNSUBSCRIBES = 10;
export const ALLOW_UNLIMITED_UNSUBSCRIBES = false;

export const NUMBER_OF_FREE_DELETES = 10;
export const ALLOW_UNLIMITED_DELETES = true;

export const DISABLE_PAYWALL = false;

export const FORCE_PAYWALL = false;

export function isPaywallDisabled() {
  if (DISABLE_PAYWALL) {
    return true;
  }

  return false;
}
